import React, { useState, useEffect, useContext } from 'react'
import PropTypes from 'prop-types'
import { Row, Col } from 'react-simple-flex-grid'
import { graphql } from 'gatsby'
import { useScrollPosition } from '@n8tb1t/use-scroll-position'
import Fade from 'react-reveal/Fade'
import classNames from 'classnames/bind'

import { Container, Heading, Text, Button, ScrollArrow, Image, BlueText } from '_atoms'
import {
  SEO,
  AndroidDevelopmentServices,
  AndroidDevelopmentBrands,
  AndroidDevelopmentResults,
  AndroidDevelopmentIndustries,
  AndroidDevelopmentTechnologies,
  AndroidDevelopmentPhases,
  AndroidDevelopmentFaq,
  ViewportObserver,
} from '_organisms'
import { Hero, QuoteContainer } from '_molecules'
import { Layout } from '_templates'
import { camelCase } from '_utils/config'
import { AppContext } from '_context'
import variables from '_config/css-variables'

import styles from './styles.module.css'

const cx = classNames.bind(styles)

export const CustomCTAButton = ({ buttonText, gtmPageName, id, type }) => {
  return (
    <Button
      type={type || 'primary'}
      className={styles.heroButton}
      href="/contact"
      id={gtmPageName + id}
    >
      {buttonText}
    </Button>
  )
}

const AndroidDevelopmentPage = ({ data }) => {
  const { setNavbarColor, setNavbarLight } = useContext(AppContext)

  const content = data?.allContentfulServicePage?.edges[0].node

  // Needed for Google Tag Manager click count on CTA buttons
  const gtmPageName = camelCase(content.name)

  /* scroll back to top when scrolling on Home, going to another page and coming back */
  useEffect(() => {
    if (typeof window !== 'undefined') window.scrollTo(0, 0)
  }, [])

  const onScrollArrowClick = () =>
    typeof window !== 'undefined' &&
    document.getElementById('services').scrollIntoView({ behavior: 'smooth' })

  const customHeroContent = (
    <Row className={styles.heroWrapper}>
      <Col xs={12} sm={12} md={7} lg={5}>
        <Fade distance="20%" top>
          <Heading type="h1" color="white" bold className={styles.heroTitle}>
            {content.heroTitle}
            <BlueText light squared>
              .
            </BlueText>
          </Heading>
        </Fade>
        <Fade distance="20%" delay={200} bottom>
          <Text size="24" className={styles.heroDescription} color="white">
            {content.heroDescription.heroDescription}
          </Text>
        </Fade>
        <Fade distance="20%" delay={200} bottom className={styles.heroButton}>
          <CustomCTAButton
            id="HeroButton"
            type="outlineWhite"
            buttonText={content.heroButtonText}
            gtmPageName={gtmPageName}
          />
        </Fade>
        <Fade distance="20%" bottom>
          <button type="button" className={styles.heroArrowButton} onClick={onScrollArrowClick}>
            <ScrollArrow className={styles.heroArrow} />
          </button>
        </Fade>
      </Col>

      <Col xs={12} sm={12} md={5} lg={5} className={styles.heroImageWrapper}>
        <Fade distance="20%" delay={350} bottom>
          <Image src={content.heroImage.file.url} />
        </Fade>
      </Col>
    </Row>
  )

  const [y, setY] = useState(0)

  useScrollPosition(
    ({ currPos }) => {
      setY(currPos.y * -1)
    },
    [],
    null,
    false,
    500
  )

  const navbarColorHandler = (color, isLightBackground) => (
    <ViewportObserver
      scroll={y}
      onViewport={() => {
        setNavbarColor(variables[color])
        setNavbarLight(isLightBackground)
      }}
    />
  )

  return (
    <Layout className={styles.androidDevelopmentPage}>
      <SEO
        title={content.pageTitle || 'Web &amp; Mobile App Design and Development Company'}
        description={
          content.pageDescription || 'Web &amp; Mobile App Design and Development Company'
        }
        thumbnail={content.pageThumbnail.file.url}
      />

      <section className={styles.heroSection}>
        <Hero
          className={styles.hero}
          contentClassName={styles.heroContent}
          customContent={customHeroContent}
        />
        {navbarColorHandler('color-primary', false)}
      </section>

      <section className={styles.servicesSection}>
        <AndroidDevelopmentServices
          androidServiceButtonText={content.androidServiceButtonText}
          androidServiceList={content.androidServiceList}
          androidServicesDescription={content.androidServicesDescription.androidServicesDescription}
          androidServicesTitle={content.androidServicesTitle}
          androidServiceImage={content.androidServiceImage.file.url}
          ctaButton={
            <CustomCTAButton
              id="ServicesButton"
              buttonText={content.androidServiceButtonText}
              gtmPageName={gtmPageName}
            />
          }
        />
        {navbarColorHandler('color-white', true)}
      </section>

      <section className={cx('whatSetUsApartSection', 'safariScrollFlickerFix')}>
        <div className={styles.whatSetUsApartSectionBlock}>
          <div className={styles.whatSetUsApartSectionBlockText}>
            <Container>
              <Heading color="white" className={styles.whatSetUsApartTitle} type="h4">
                {content.testimonialsTitle}
                <BlueText squared> ?</BlueText>
              </Heading>
            </Container>

            <QuoteContainer
              items={content.testimonials}
              isWhite
              className={styles.whatSetUsApartQuote}
              sliderClass={styles.whatSetUsApartQuoteSlider}
              iconsClass={styles.whatSetUsApartQuoteIcons}
            />

            {navbarColorHandler('color-dark-bg', false)}
          </div>
        </div>
      </section>

      <section className={styles.joinTheClubSection}>
        <Container>
          <Col justify="center" align="middle">
            <Text size="28" className={styles.joinTheClubSectionText}>
              {content.joinText.joinText}
              <BlueText light squared className={styles.blueDot}>
                .
              </BlueText>
            </Text>
            <CustomCTAButton
              id="JoinTheClubButton"
              buttonText={content.joinButtonText}
              gtmPageName={gtmPageName}
            />
          </Col>
        </Container>

        {navbarColorHandler('color-white', true)}
      </section>

      <section className={styles.brandsSection}>
        <AndroidDevelopmentBrands
          trustedBrandsTitle={content.trustedBrandsTitle}
          trustedBrandsDescription={content.trustedBrandsDescription.trustedBrandsDescription}
          trustedBrands={content.trustedBrands}
        />
        {navbarColorHandler('color-white', true)}
      </section>

      <section className={styles.resultsSection}>
        <AndroidDevelopmentResults
          resultsData={content.resultsData}
          resultsTitle={content.resultsTitle}
          resultsDescription={content.resultDescription.internal.content}
        />
        {navbarColorHandler('color-primary', false)}
      </section>

      <section className={styles.industriesSection}>
        <AndroidDevelopmentIndustries
          industriesTitle={content.industriesTitle}
          industries={content.industries}
          industriesDescription={content.industriesDescription}
        />
        {navbarColorHandler('color-white', true)}
      </section>

      <section className={styles.phasesSection}>
        <AndroidDevelopmentPhases
          developmentPhases={content.developmentPhases}
          developmentPhasesTitle={content.developmentPhasesTitle}
          developmentPhasesDescription={content.developmentPhasesDescription}
        />
        {navbarColorHandler('color-white', true)}
      </section>

      <section className={styles.technologiesSection}>
        <AndroidDevelopmentTechnologies
          developmentTechnologies={content.developmentTechnologies}
          developmentTechnologiesTitle={content.developmentTechnologiesTitle}
          developmentTechnologiesDescription={content.developmentTechnologiesDescription}
        />
        {navbarColorHandler('color-white', true)}
      </section>

      <section className={styles.faqSection}>
        <AndroidDevelopmentFaq
          faq={content.faq}
          faqTitle={content.faqTitle}
          faqImage={content.faqImage}
        />
        {navbarColorHandler('color-white', true)}
      </section>
    </Layout>
  )
}

export const query = graphql`
  query AndroidDevelopmentPageContent($locale: String) {
    allContentfulServicePage(
      filter: {
        name: { eq: "Android App Development Company" }
        fields: { localeKey: { eq: $locale } }
      }
    ) {
      edges {
        node {
          name
          pageTitle
          pageDescription
          pageThumbnail {
            file {
              url
            }
          }
          heroTitle
          heroDescription {
            heroDescription
          }
          heroImage {
            file {
              url
            }
          }
          heroButtonText
          androidServicesTitle
          androidServicesDescription {
            androidServicesDescription
          }
          androidServiceList {
            title
            description
          }
          androidServiceButtonText
          androidServiceImage {
            file {
              url
            }
          }
          trustedBrandsTitle
          trustedBrandsDescription {
            trustedBrandsDescription
          }
          trustedBrands {
            brandLogo {
              file {
                url
              }
            }
            brandName
            brandDescription {
              brandDescription
            }
            brandLink
            brandImage {
              file {
                url
              }
            }
          }
          resultsTitle
          resultDescription {
            internal {
              content
            }
          }
          resultsData {
            title
            value
            description
          }
          resultsImage1 {
            file {
              url
            }
          }
          resultsImage2 {
            file {
              url
            }
          }
          resultsImage3 {
            file {
              url
            }
          }
          industriesTitle
          industriesDescription
          industries {
            image {
              file {
                url
              }
            }
            imageTitle
            imageDescription {
              imageDescription
            }
          }
          developmentPhasesTitle
          developmentPhasesDescription
          developmentPhases {
            image {
              file {
                url
              }
            }
            imageTitle
            imageSubtitle
            imageDescription2 {
              imageDescription2
            }
            imageDescription {
              imageDescription
            }
          }
          developmentTechnologiesTitle
          developmentTechnologiesDescription
          developmentTechnologies {
            image {
              file {
                url
              }
            }
            title
          }
          testimonialsTitle
          testimonials {
            id
            text {
              internal {
                content
              }
            }
            user
            jobDescription
            userImage {
              fixed(width: 64, height: 64, quality: 80) {
                src
                srcSet
              }
            }
          }
          joinText {
            joinText
          }
          joinButtonText
          faqTitle
          faqImage {
            file {
              url
            }
          }
          faq {
            question
            answer
          }
        }
      }
    }
  }
`

AndroidDevelopmentPage.propTypes = {
  data: PropTypes.instanceOf(Object).isRequired,
}

CustomCTAButton.propTypes = {
  buttonText: PropTypes.string.isRequired,
  gtmPageName: PropTypes.string.isRequired,
  id: PropTypes.string.isRequired,
  type: PropTypes.string.isRequired,
}

export default AndroidDevelopmentPage
